@import "mixin";



@import "../images/sprite"; /*DEFINE DEFAULT BASE FONT STYLES IN PX*/


$base-font-size: 16px; /*DEFINE WEBSITE COLORS*/
$link-color: #ff8d2a; //change this
$blue-color: #284564;

$font-color:#333333;

/*DEFINE CUSTOM FONTS (remove if not needed)*/
/*START TEMPLATE STYLES*/
*:focus {
	outline:none !important;
}

a,
img {
	border: none;
	text-decoration: none;
	outline: 0;
}

img {
	display: block;
	max-width: 100%;
}

*:focus {
	outline: 0;
}

* {
	@include box-sizing;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700; //change this if not using google fonts
}
// Remove if we DON'T use Google fonts
footer ul,
header ul {
	list-style: none;
}

fieldset {
	border: 0;
}

input {
	padding: 0;
	margin: 0;
}

a {
	color: $link-color;
	@include color-transition;

	&:hover {
		color: $font-color;
	}
}

.pageAlign {
	margin: 0 auto;
	max-width: 1324px;
	padding: 0 50px;
}

.pageAlign2 {
	margin: 0 auto;
	max-width: 800px;
	padding: 0 50px;
}

.pageAlignFluid {
	margin: 0 auto;
	max-width: 2020px;
	padding: 0 50px;
}

.relative {
	position: relative;
}

.fullWidth {
	float: left;
	width: 100%;
}

.invisible {
	display: none;
}

.fl {
	float: left;
}

.fr {
	float: right;
}

html {
	font-size: 62.5%;
	height: 100%;
}

body,
input,
select,
textarea {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	/*REMOVE if we DON'T use Google fonts*/
}

body {
	width: 100%;
	padding: 0;
	margin: 0;
	min-height: 100%;
	height: 100%;
	min-width: 100%;
	color: $font-color;
	font-size: $base-font-size;
	background: #fff;
}

p {
	margin-bottom: 3rem;
	line-height: 28px;
}

::selection {
	background: $link-color;
	/* WebKit/Blink Browsers */
	color: #fff;
}

::-moz-selection {
	background: $link-color;
	/* Gecko Browsers */
	color: #fff;
}

.responsive-nav {
	display: block;
	margin:40px 0 0 20px;
	color: $blue-color;
	cursor: pointer;
	float:right;
	@include all-transition;
	display:none;
	button {
		padding: 7px 10px;
		background: none;
		border: none;
		position: relative;
		height: 40px;
		width: 51px;
		cursor: pointer;
		outline: none;
		@include all-transition;
		float: right;
		&:hover {
			.line {
				background: $blue-color;
			}
		}
	}
}

.line {
	position: absolute;
	height: 3px;
	width: 30px;
	background: $blue-color;
	left: 10px;
	@include all-transition;
}

.line-t {
	top: 9px;
}

.line-m {
	top: 19px;
}

.line-b {
	top: 29px;
}

.menu-on .line-m,
.menu-on:hover .line-m {
	background: transparent;
	opacity:0;
}

.menu-on .line {
	top: 18px;
}

.menu-on .line-t {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.menu-on .line-b {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
