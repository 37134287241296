@import "settings";

#login form {
	box-shadow:none !important;
}

.widget_rotatingtweets_widget {
	margin:20px 0;
}

.rotatingAuthorTweets {
	border-top:1px solid #ccc;
	margin-top: 30px;
	font-size:14px;
	h3 {
		color:$blue-color;
	}
	a {
		color:#000;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.rtw_pager a{
		margin:0 2px;
		color:$blue-color;
		&.activeSlide {
			color:$link-color;
		}
	}
}

.scroll-up {
	position:fixed;
	bottom:0;
	right:0;
	z-index:10;
	width:45px;
	height:45px;
	background:$link-color;
	cursor:pointer;
	transition:all 0.3s;
	opacity:0;
	visibility: visible;
	&.active {
		opacity:1;
		visibility: visible;
	}
	&:hover {
		background:$font-color;
	}
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:url(../images/arrow-white.png) no-repeat center center;
		background-size:auto 30%;
	}
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing, div.wpcf7-mail-sent-ng, div.wpcf7-aborted {
	background:$blue-color;
	border:none !important;
	color:#fff;
	padding:15px 25px !important;
	margin:50px 30px 0 30px !important;
	clear:both;
}
div.wpcf7-mail-sent-ok {
	background:$link-color;
	border:none !important;
	color:#fff;
	padding:15px 25px !important;
	margin:50px 30px 0 30px !important;
	clear:both;
}
span.wpcf7-not-valid-tip {
	color:#fff !important;
	background: $blue-color;
    padding: 5px;
    color: #fff;
    font-size: 11px !important;
}
div.wpcf7 .ajax-loader {
	margin:-73px 0 0 10px !important;
}
div.wpcf7 {
	.ajax-loader {
		background-image:url(../images/ajax-loader.svg) !important;
	}
}

.nice-select {
	float:left;
	clear:none;
	margin-right: 10px;
	border-radius: 0;
	.list {
		border-radius:0;
		margin-top: 1px;
	}
}

.single-detail .content {
	.post-content {
		p:nth-child(2) {
			font-size:24px;
			line-height:1.5;
		}
	}
}

.share-icons {
	padding:20px 0;
	border-top:1px solid $blue-color;
	margin-top: 8rem;
	div {
		float:left;
		margin-right: 15px;
		color:$blue-color;
		font-size:16px;
		&:first-child {
			margin-top: 3px;
		}
	}
}

.pagination {
	.container {
		text-align: center;
		display:block;
		padding:0;
	}
	a,span {
		display:inline-block;
		height:32px;
		padding:0 10px;
		line-height:32px;
		text-align:center;
		background:red;
		margin:0 3px;
	}
	a {
		color:$font-color;
		background:#ececec;
		&:hover {
			color:$link-color;
		}
	}
	span {
		background:$link-color;
		color:#fff;
	}
}

body.admin-bar {
	header {
		//top:32px;
	}
}

.container {
	@include clearfix;
}

.single-page {
	padding:8rem 0;
	h1,h2,h3,h4,h5,h6 {
		color:$blue-color;
	}
	ul {
		li {
			margin:10px 0;
		}
	}
}

header {
	position:fixed;
	top:0;
	left:0;
	right:0;
	background:#fff;
	z-index:20;
	box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
	&.small-header {
		box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
		.top-bar {
			opacity:0;
			visibility: hidden;
		}
		.main-header {
			transform: translateY(-20px);
			padding:0;
			a.logo {
				width:150px;
				height:48px;
				margin-top: 0;
			}
			nav {
				margin-top:5px;
				.countries-dropdown {
					top:48px !important;
				}
			}
			form {
				input, .close-search {
					top:11%;
				}
			}
			.language {
				margin-top: 4px;
			}
			.search-trigger {
				margin:19px 0 0 30px;
			}
			.responsive-nav {
				margin-top: 10px;
			}
		}
	}
	.top-bar {
		@include clearfix;
		transition:all 0.3s;
		nav {
			float:right;
			ul {
				margin:0;
				padding:0;
				font-size:13px;
				li {
					display:inline-block;
					a {
						display:block;
						padding:10px 20px;
						color:$blue-color;
					}
					&:hover, &.current_page_item {
						a {
							color:$link-color;
						}
					}
				}
			}
		}
		.social {
			float:right;
			margin:10px 0 0 50px;
			a {
				float:left;
				display:inline-block;
				background:no-repeat center center;
				background-size:contain;
				margin:0 8px;
				transition:all 0.3s;
				&:hover {
					opacity:0.7;
				}
			}
			a.fb {
				width:11px;
				height:19px;
				background-image:url(../images/facebook.png);
			}
			a.tw {
				margin-top: 2px;
				width:19px;
				height:15px;
				background-image:url(../images/twitter.png);
			}
			a.yt {
				margin-top: 4px;
				width:19px;
				height:13px;
				background-image:url(../images/youtube.png);
			}
		}
	}
	.main-header {
		font-size:17px;
		font-weight:300;
		@include clearfix;
		padding:5px 0 0 0;
		transition:all 0.3s;
		position:relative;
		a.logo {
			float:left;
			width:209px;
			height:71px;
			background:url(../images/logo-header.svg) no-repeat center center;
			background-size:contain;
			transition:all 0.3s;
			margin-top: -10px;
		}
		nav {
			float:right;
			margin-top: 40px;
			transition:all 0.3s;
			> ul {
				margin:0;
				padding:0;
				> li {
					display:inline-block;
					margin:0 30px;
					transition:all 0.3s;
					position:relative;
					&.menu-item {
						display:none;
					}
					&:not(.hassub) {
						margin-right:10px;
					}
					&.hassub {
						position:relative;
						span.arrow {
							position:absolute;
							top:10px;
							right:-40px;
							width:40px;
							height:30px;
							background:url(../images/arrow-blue.png) no-repeat center center;
						}
					}
					> a {
						display:block;
						padding:10px 0;
						color:$blue-color;
						border-bottom:2px solid transparent;
						transition:all 0.3s;
						&.active-link {
							color:$link-color;
							border-bottom:2px solid $link-color;
						}
					}
					&:hover, &.active-link {
						> a {
							color:$link-color;
							border-bottom:2px solid $link-color;
						}
						ul {
							visibility: visible;
							opacity:1;
							transform:translateY(0);
							&.countries-dropdown {
								transform:translateY(0) translateX(-50%);
							}
						}
					}
					ul {
						position:absolute;
						top:45px;
						left:0;
						width:230px;
						background:#fff;
						margin:0;
						padding:20px;
						visibility: hidden;
						opacity:0;
						transform:translateY(30px);
						transition:all 0.3s;
						li {
							font-size:14px;
							display:block;
							&:hover {
								a {
									color:$link-color;
								}
							}
							a {
								border-bottom: 1px solid #ccc;
								display:inline-block;
								padding:10px 0;
								color:$font-color;
								&.active-link {
									color:$link-color;
								}
							}
						}
						&.countries-dropdown {
							transform:translateY(30px) translateX(-50%);
							position:fixed;
							left:50%;
							right:0;
							width:100%;
							//max-width:1224px;
							top:128px;
							box-shadow: 0 5px 5px 0 rgba(0,0,0,.1);
							width:5000px;
							.pageAlign {
								max-width:900px;
							}
							li {
								float:left;
								width:33.3333%;
							}
						}
					}
				}
			}
		}
		form {
			position:absolute;
			top:0;
			left:300px;
			right:0;
			bottom:0;
			background:#fff;
			opacity:0;
			visibility: hidden;
			transition:all 0.3s ease-in-out;
			&.active {
				transform:translateY(0);
				opacity:1;
				visibility: visible;
			}
			input {
				display:block;
				position:absolute;
				top:28%;
				left:0;
				right:0;
				width:100%;
				height:50px;
				background:#fff;
				border:1px solid $blue-color;
				line-height:40px;
				padding:0 200px 0 20px;
				font-size:14px;
				box-sizing: border-box;
			}
			input[type="submit"] {
				width:140px;
				left:auto;
				right:50px;
				background:$blue-color;
				border:1px solid $blue-color;
				color:#fff;
				text-align:center;
				font-weight:700;
				padding:0;
				transition:all 0.3s;
				&:hover {
					background-color:$link-color;
					border-color:$link-color;
				}
			}
			.close-search {
				display:block;
				position:absolute;
				top:28%;
				right:0;
				width:50px;
				height:50px;
				background:$link-color url(../images/close.png) no-repeat center center;
				cursor:pointer;
			}
		}
		.language {
			float:right;
			position:relative;
			margin-top: 40px;
			transition:all 0.3s;
			margin-left: 15px;
			.selected {
				color:#fff;
				background:$link-color;
				padding:10px 40px 10px 15px;
				cursor:pointer;
				transition:all 0.3s;
				position:relative;
				&:hover {
					background:$blue-color;
				}
				&:after {
					content:"";
					display:block;
					position:absolute;
					top:50%;
					right:15px;
					transform:translateY(-50%);
					width:11px;
					height:6px;
					background:url(../images/arrow.png);
				}
			}
			.wpml-ls ul {
				visibility: hidden;
				opacity:0;
				transition:all 0.3s;
				transform:translateY(15px);
				position:absolute;
				background:$font-color;
				top:43px;
				left:0;
				right:0;
				margin:0;
				padding:10px 0;
				font-size:13px;
				font-weight:400;
				&.active {
					visibility: visible;
					opacity: 1;
					transform:translateY(0);
				}
				li {
					a {
						color:#fff;
						padding:5px 15px;
						display:block;
						&:hover {
							color:$link-color;
						}
					}
				}
			}
		}
		.search-trigger {
			float:right;
			margin:51px 0 0 30px;
			width:20px;
			height:20px;
			background:url(../images/search.png) no-repeat center center;
			background-size:contain;
			cursor:pointer;
			transition:all 0.3s;
			&:hover {
				opacity:0.7;
			}
		}
	}
}

.featured {
	background:$blue-color;
	padding:20px 0 40px 0;
	color:#fff;
	.date {
		text-align:right;
		font-size:14px;
	}
	.container {
		margin:0 -20px;
	}
	.article {
		float:left;
		width:30%;
		padding:20px;
		position:relative;
		.article-link {
			position: absolute;
		    top: 20px;
		    left: 20px;
		    right: 20px;
		    bottom: 20px;
		    z-index: 1;
		}
		.inside {
			height:0;
			padding-bottom:68.8%;
			position:relative;
			background: #284564 url(../images/placeholder.jpg) no-repeat 50%;
			background-size:auto;
			background-repeat:no-repeat;
			background-position:center center;
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background:rgba(0,0,0,0.3);
			}
			&:before {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background: rgb(0,0,0);
				background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 80%);
			}
			.content {
				padding-left:20px;
				position:absolute;
				bottom:0;
				left:0;
				z-index:1;
				span.category {
					display:inline-block;
					background:$blue-color;
					color:#fff;
					font-size:12px !important;
					transition:all 0.3s;
					padding:5px;
					font-weight:700;
					&.country {
						background:$link-color;
					}
					&.multimedia {
						background:#ad2020;
					}
					a {
						color:#fff;
						&:hover {
							text-decoration: underline;
						}
					}
				}
				a {
					color:#fff;
					&:hover {
						h2 {
							color:$link-color;
						}
					}
				}
				h2 {
					font-size:15px;
					font-weight:300;
					transition:all 0.3s;
					margin:0;
					padding:10px 0 20px 0;
				}
				p {
					margin:0;
					padding:0 0 20px 0;
					display:none;
				}
			}
		}
	}
	.article:first-child {
		width:70%;
		.inside {
			padding-bottom:60%;
			.content {
				padding-left:50px;
				a.category {
					font-size:18px;
				}
				h2 {
					font-size:30px;
					font-weight:400;
				}
				p {
					display:block;
				}
			}
		}
	}
}

section {
	padding:5rem 0;
	.top {
		@include clearfix;
		h2 {
			float:left;
			font-size:3.6rem;
			color:$blue-color;
			margin:0;
		}
	}
}
.home-articles {
	.top {
		.filters {
			select {
				border:none;
				border-bottom:1px solid $blue-color;
				color:$blue-color;
				padding:10px 10px 10px 0;
				font-size:14px;
				margin-left:20px;
				option {
					border:none;
				}
			}
		}
		h2 {
			float:none;
			margin-bottom: 15px;
		}
		a {
			float:right;
			font-size:14px;
			border-bottom:1px solid $blue-color;
			color:$blue-color;
			padding:11px 0;
			margin-left:20px;
		}
	}
	.container {
		margin:0 -13px;
		padding-top:30px;
		text-align:center;
	}
	.article {
		float:left;
		width:33.33333%;
		padding:13px;
		text-align: left;
		&:nth-child(3n +1) {
			clear:left;
		}
		a {
			h2 {
				color:$blue-color;
				transition:all 0.3s;
			}
			&:hover {
				h2 {
					color:$link-color;
				}
			}
		}
		.image {
			display:block;
			position:relative;
			height:0;
			padding-bottom:60%;
			background:$blue-color url(../images/placeholder.jpg) no-repeat center center;
			background-size:contain;
			.img-resize {
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				overflow: hidden;
				img {
					max-width:none;
				}
			}
			iframe {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				width:100%;
				height:100%;
			}
			.category-container {
				position:absolute;
				bottom:10px;
				left:10px;
				z-index:3;
			}
			.category {
				float:left;
				background:$blue-color;
				color:#fff;
				font-size:14px;
				font-weight:700;
				display:block;
				padding:5px 10px;
				&.country {
					background:$link-color;
				}
				&.multimedia {
					background:#ad2020;
				}
				a {
					color:#fff;
					&:hover {
						color:#fff;
						text-decoration:underline;
					}
				}
			}
		}
		.duration {
			font-size:14px;
			color:#666666;
			margin-top: 10px;
		}
		h2 {
			font-size:20px;
			a {
				color:$blue-color;
				&:hover {
					color:$link-color;
				}
			}
		}
		p {
			font-size:14px;
			line-height:23px;
		}
	}
	.my-lazyloader-btn-container, .my-lazyloader-wrapper, .my-lazyloader-content {
		float:left;
		width:100%;
	}
	.my-lazyloader-btn {
		background:transparent;
		font-size:14px;
		text-transform: uppercase;
		font-weight:300;
		color:$blue-color;
		display:inline-block;
		border:1px solid $blue-color;
		padding:16px 40px;
		margin-top: 50px;
		cursor:pointer;
		transition:all 0.3s;
		height:auto;
		width:auto;
		border-radius:0;
		&.my-lazyloader-btn-disabled {
			opacity: 0;
			visibility: hidden;
			cursor: not-allowed;
		}
		&:before {
			display:none;
		}
		&:not(.my-lazyloader-btn-disabled):hover {
			background:$blue-color;
			color:#fff;
		}
	}
}
.trending {
	background:#f5f5f5;
	.top {
		h2 {
			a {
				text-transform: capitalize;
			}
		}
	}
	.container {
		display:flex;
		flex-wrap:wrap;
		margin:20px -23px 0 -23px;
	}
	.main {
		float:left;
		width:100%;
		padding:0 23px 23px 23px;
		.inside {
			height:0;
			padding-bottom: 40%;
			position:relative;
			color:#fff;
			background:$blue-color url(../images/placeholder.jpg) no-repeat center center;
			background-size:cover;
			&:before {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background: rgb(0,0,0);
				background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 80%);
			}
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background:rgba(0,0,0,0.3);
			}
			.content {
				position:absolute;
				bottom:0;
				left:0;
				right:0;
				padding:20px 40px;
				z-index:1;
				.left {
					float:left;
					width:70%;
					display:block;
					h2 {
						font-size:4.8rem;
						margin:0;
						a:hover {
							color:#fff;
						}
					}
					a.btn {
						display:inline-block;
						background:$link-color;
						color:#fff;
						text-transform: uppercase;
						padding: 16px 40px;
						font-weight:300;
						font-size:14px;
						transition: all 0.3s;
						margin-top: 15px;
						&:hover {
							background:$blue-color;
						}
					}
				}
				.right {
					display:block;
					float:right;
					width:30%;
					a {
						text-decoration:underline;
						color:#fff;
						text-transform: uppercase;
						&:hover {
							color:$link-color;
						}
					}
				}
			}
		}
	}
	.article {
		width:25%;
		float:left;
		padding:23px;
		position:relative;
		.article-link {
			position:absolute;
			top:23px;
			left:23px;
			right:23px;
			bottom:23px;
			z-index:1;
		}
		.inside {
			height:0;
			padding-bottom:65%;
			position:relative;
			background:$blue-color url(../images/placeholder.jpg) no-repeat center center;
			background-size:cover;
			&:before {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background: rgb(0,0,0);
				background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 80%);
			}
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background:rgba(0,0,0,0.3);
			}
			h3 {
				padding:20px;
				font-size:16px;
				margin:0;
				font-weight:400;
				position:relative;
				z-index:1;
				a {
					color:#fff;
					&:hover {
						color:$link-color;
					}
				}
			}
			a.category {
				display:inline-block;
				position:absolute;
				bottom:10px;
				left:10px;
				background:#fff;
				padding:5px 10px;
				font-size:12px;
				color:$blue-color;
				transition:all 0.3s;
				z-index:1;
				&:hover {
					background:$link-color;
					color:#fff;
				}
			}
		}
		&:nth-child(3n+1) {
			clear:left;
		}
	}
}

.multimedia {
	background:$blue-color;
	color:#fff;
	.top {
		h2 {
			color:$link-color;
		}
		a {
			float:right;
			font-size:14px;
			border-bottom:1px solid $link-color;
			color:$link-color;
			padding:11px 0;
			margin-left:20px;
			transition:all 0.3s;
			&:hover {
				color:#fff;
				border-bottom: 1px solid #fff;
			}
		}
	}
	.container {
		margin:0 -20px;
	}
	.article {
		float:left;
		width:30%;
		padding:20px;
		.inside {
			height:0;
			padding-bottom:68.8%;
			background:#000;
			position:relative;
			.category-container {
				position: absolute;
				bottom: 10px;
				left: 10px;
				z-index: 3;
				.category {
					float: left;
				    background: #284564;
				    color: #fff;
				    font-size: 14px;
				    font-weight: 700;
				    display: block;
				    padding: 5px 10px;
					&.country {
						background:$link-color;
					}
					&.multimedia {
						background:#ad2020;
					}
					a {
						color:#fff;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
			iframe {
				height:100%;
				width:100%;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
			}
		}
		.content {
			.duration {
				font-size:12px;
				color:$link-color;
				margin-top: 10px;
			}
			h2 {
				font-size:14px;
				font-weight:400;
				color:#fff;
			}
			a {
				&:hover {
					h2 {
						color:$link-color;
					}
				}
			}
			p {
				display:none;
			}
		}
	}
	.article:first-child {
		width:70%;
		.inside {
			padding-bottom:56.25%;
		}
		.content {
			h2 {
				font-size:3.6rem;
			}
			a {
				h2 {
					color:$link-color;
					&:hover {
						color:#ffF;
					}
				}
			}
			p {
				display:block;
			}
		}
	}
}

.editors-pick {
	&.type2 {
		.slide {
			.inside {
				background-color:transparent !important;
				background-size:cover !important;
			}
		}
	}
	.container {
		margin:0 -20px;
	}
	.slide {
		float:left;
		width:316px;
		height:370px;
		padding:20px;
		.inside {
			height:100%;
			position:relative;
			background:$blue-color url(../images/placeholder.jpg) no-repeat center center;
			background-size:auto;
			display:block;
			color:#fff;
			.article-link {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
			}
			&:hover {
				color:$link-color;
			}
			&:before {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background: rgb(0,0,0);
				background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 80%);
			}
			h2 {
				margin:0;
				padding:0;
				line-height:24px;
				a {
					color:#fff;
					font-size:18px;
					&:hover {
						color:$link-color;
					}
				}
			}
			.nr {
				font-size:8rem;
				color:$link-color;
				font-weight:700;
			}
			.content {
				position:absolute;
				bottom:20px;
				left:10px;
				right:10px;
			}
		}
		&.big-slide {
			width:634px;
			padding:20px;
			.inside {
				background-color:$blue-color;
				h2 {
					margin:0;
					padding:0;
					line-height:30px;
					a {
						color:#fff;
						font-size:24px;
						&:hover {
							color:$link-color;
						}
					}
				}
			}
		}
	}
	.slick-prev, .slick-next {
		width:39px;
		height:41px;
		background:url(../images/arrow-blue-big.png) no-repeat center center;
		background-size:cover;
		text-indent:-9999999px;
		overflow: hidden;
		transition:all 0.3s;
		&:hover {
			opacity:0.7;
		}
		&:before {
			display:none;
		}
	}
	.slick-next {
		right:-50px;
	}
	.slick-prev {
		left:-50px;
		transform:translate(0, -50%) rotate(180deg);
	}
}

.subscribe	{
	background:$link-color;
	color:#fff;
	padding:40px 0;
	h2 {
		font-size:24px;
		font-weight:400;
		margin:0;
	}
	form {
		margin:10px 0 0 0;
		padding:0;
		input, button {
			float:left;
			width:74%;
			background:#fff;
			padding:15px;
			font-size:16px;
			font-family: 'Open Sans', sans-serif;
			border:none;
			border:2px solid #fff;
		}
		input[type="submit"], button {
			float:right;
			background:none;
			color:#fff;
			width:23%;
			cursor:pointer;
			transition:all 0.3s;
			&:hover {
				background:#fff;
				color:$link-color;
			}
		}
	}
}
footer {
	.top {
		background:#1a1a1a;
		padding:8rem 0;
		color:#fff;
		font-size:14px;
		a {
			color:#fff;
			&:hover {
				color:$link-color;
			}
		}
		img {
			max-width:200px;
		}
	}
	.bottom {
		background:#fff;
		font-size:14px;
		padding:40px 0;
		ul {
			margin:0;
			padding:0;
			li {
				display:inline-block;
				&:last-child a:after {
					display:none;
				}
				a {
					display:inline-block;
					color:$font-color;
					&:hover {
						color:$link-color;
					}
					&:after {
						content:"|";
						display:inline-block;
						margin:0 5px;
						color:$font-color !important;
					}
				}
			}
		}
		.left {
			float:left;
		}
		.right {
			float:right;
		}
	}
	h3 {
		font-size:24px;
		font-weight:400;
		border-bottom:3px solid $link-color;
		color:$link-color;
		margin-bottom: 15px;
		padding-bottom: 8px;
		&.smallh3 {
			font-size:18px;
			border-bottom: 1px solid $link-color;
		}
	}
	.col30 {
		float:left;
		width:33.33333%;
		padding-right:80px;
		&:last-child {
			padding-right: 0;
		}
		ul {
			margin:0;
			padding:0;
			float:left;
			width:50%;
			padding-right: 20px;
			li {
				margin:5px 0 10px 0;
			}
		}
		a.social {
			float:left;
			width:38px;
			height:37px;
			transition:all 0.3s;
			background:no-repeat center center;
			background-size:contain;
			margin-right:10px;
			&:hover {
				opacity:0.7;
			}
			&.fb {
				background-image:url(../images/facebook2.svg);
			}
			&.tw {
				background-image:url(../images/twitter2.svg);
			}
			&.yt {
				background-image:url(../images/youtube2.svg);
			}
			&.rss {
				background-image:url(../images/rss2.svg);
			}
		}
	}
}

.breadcrumbs {
	padding:20px 0;
	color:#fdfdfd;
	font-size:14px;
	font-weight:400;
	background:#bababa !important;
	color:#fff;
	@include clearfix;
	a {
		color:#fff;
		&:hover {
			color:$font-color;
		}
	}
	ul {
		margin:0;
		padding:0;
		li {
			&:first-child {
				margin-left: 0;
			}
			margin:0 10px;
			display:inline-block;
		}
	}
}
.subpage-banner {
	.top {
		margin-bottom: 30px;
	}
	.box {
		@include clearfix;
		display:flex;
		flex-wrap:wrap;
		align-items: center;
		background:$link-color;
	}
	.image {
		float:left;
		width:60%;
		height:0;
		padding-bottom: 40%;
		position:relative;
		overflow:hidden;
		.img-resize {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			img {
				max-width:none;
			}
		}
	}
	.youtube-container {
		float:left;
		width:60%;
		padding-bottom: 33.75%;
		position:relative;
		iframe {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			width:100%;
			height:100%;
		}
	}
	.right {
		float:left;width:40%;
		padding:40px;
		background:$link-color;
		h2,h3,h4,h5 {
			color:#fff;
		}
		h2 {
			font-size:4rem;
		}
		h3 {
			font-size:3.6rem;
		}
		h4 {
			font-size:20px;
		}
	}
}
.column-image {
	.left {
		float:left;
		width:60%;
		padding-right:50px;
	}
	.right {
		float:right;
		width:40%;
	}
}
.two-columns {
	.container {
		margin:0 -40px;
	}
	.column {
		float:left;
		width:50%;
		padding:40px;
	}
	.image {
		height:0;
		padding-bottom:50%;
		position:relative;
		.img-resize {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			overflow: hidden;
			img {
				max-width:none;
			}
		}
	}
	h2,h3,h4,h5,h6 {
		font-weight:400;
	}
	a {
		color:$font-color;
		&:hover {
			color:$link-color;
		}
	}
	p {
		font-size:14px;
	}
}

.authors {
	position: relative;
	z-index: 2;
	.top {
		h2 {
			color:#fff;
		}
	}
	.container {
		margin:40px -10px 0 -10px;
	}
	.author {
		float:left;
		width:25%;
		padding:10px;
		position:relative;
		margin-bottom:60px;
		.image {
			height:0;
			padding-bottom:100%;
			position:relative;
			a:hover {
				img {
					filter: grayscale(0);
				}
			}
			.img-resize {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				overflow: hidden;
				img {
					filter: grayscale(100%);
					max-width:none;
					transition:all 0.3s;
				}
			}
		}
		.content {
			position:absolute;
			background:#fff;
			bottom:-40px;
			left:40px;
			right:70px;
			padding:20px 30px;
			span {
				display:block;
				color:$blue-color;
				font-size:14px;
			}
			a {
				color:$link-color;
				font-weight:700;
				font-size:18px;
				&:hover {
					color:$blue-color;
				}
			}
		}
	}
}
.author-information {
	.left {
		float:left;
		width:40%;
	}
	.right {
		float:left;
		width:60%;
		padding-left: 60px;
	}
	.category {
		color:$link-color;
	}
	.author-name {
		font-size:28px;
		color:$blue-color;
	}
	.author-contacts {
		border-top:1px solid #ccc;
		padding-top:30px;
		@include clearfix;
	}
	.circle {
		width:90px;
		height:90px;
		border:2px solid $link-color;
		float:left;
		border-radius:100%;
		text-align: center;
		margin-right:30px;
		> div {
			color:#9f9f9f;
			font-size:12px;
			padding-top:22px;
		}
		.nr {
			padding-top: 0;
			font-size:20px;
			color:$blue-color;
			font-weight:700;
		}
	}
	.socials {
		float:right;
		padding-top:25px;
		a {
			display:block;
			float:left;
			transition:all 0.3s;
			opacity:0.5;
			margin-left:25px;
			background-size: contain !important;
			&:hover {
				opacity:1;
			}
		}
		a.tw {
			width:33px;
			height:29px;
			background:url(../images/twitter3.svg) no-repeat center center;
		}
		a.fb {
			width:26px;
			height:29px;
			background:url(../images/facebook3.svg) no-repeat center center;
		}
		a.insta {
			width:27px;
			height:29px;
			background:url(../images/instagram3.svg) no-repeat center center;
		}
		a.web {
			width:30px;
			height:30px;
			background:url(../images/globe3.svg) no-repeat center center;
		}

		a.yt{
			width:30px;
			height:30px;
			background:url(../images/youtube3.svg) no-repeat center center;
		}
		a.ln {
			width:30px;
			height:30px;
			background:url(../images/linkedin3.svg) no-repeat center center;
		}
		a.pt {
			width:30px;
			height:30px;
			background:url(../images/pinterest3.svg) no-repeat center center;
		}
		a.wiki {
			width:30px;
			height:30px;
			background:url(../images/wikipedia3.svg) no-repeat center center;
		}
		a.sc {
			width:30px;
			height:30px;
			background:url(../images/soundcloud3.svg) no-repeat center center;
		}
		a.myspace {
			width:30px;
			height:30px;
			background:url(../images/myspace3.svg) no-repeat center center;
		}
		a.tr {
			width:30px;
			height:30px;
			background:url(../images/tumblr3.svg) no-repeat center center;
		}

	}
	.author-image {
		position:relative;
		.play-icon {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background:url(../images/play.png) no-repeat center center;
			transition:all 0.3s;
			cursor:pointer;
			&:hover {
				background-color:rgba(255,255,255,0.5);
			}
		}
		img {
			min-width:100%;
		}
	}
	.youtube-video {
		padding:0 100px 10rem 100px;
		display:none;
		.inside {
			position:relative;
			height:0;
			padding-bottom: 56.25%;
			iframe {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				width:100%;
				height:100%;
			}
		}
	}
}

.contact-section {
	.map {
		height:0;
		padding-bottom: 30%;
		position:relative;
		overflow: hidden;
		margin:60px 0;
		iframe {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			width:100%;
			height:100%;
		}
	}
	.top {
		> h3 {
			color:$blue-color;
			border-bottom:1px solid #e6e6e6;
			padding-bottom:10px;
			font-weight:400;
			margin-top: 0;
			font-size:24px;
		}
	}
	.container {
		.left {
			float:left;
			width:65%;
			padding-right:80px;
			border-right:1px solid #e6e6e6;
		}
		.right {
			float:left;
			width:35%;
			padding-left:50px;
		}
	}
	.col50,.col100 {
		float:left;
		width:100%;
		padding:15px;
	}
	.col50 {
		width:50%;
	}
	form {
		margin:0 -15px;
		input, textarea {
			border:none;
			display:block;
			width:100%;
			padding:13px 10px;
			background:#f2f2f2;
			color:$blue-color;
			font-size:16px;
			font-family: 'Open Sans', sans-serif;
			transition:all 0.3s;
			&:focus {
				background:$blue-color;
				color:#fff;
			}
		}
		textarea {
			min-width:100%;
			max-width:100%;
			min-height:250px;
			max-height:600px;
		}
		input[type="submit"] {
			background:$link-color;
			color:#fff;
			cursor:pointer;
			&:focus {
				background:$link-color;
			}
			&:hover {
				background:$blue-color;
			}
		}
	}
	.row {
		@include clearfix;
		margin-bottom: 50px;
		.icn {
			float:left;
			width:35%;
			padding-top:40px;
		}
		h4 {
			font-size:24px;
			font-weight:400;
			margin-bottom: 20px;
		}
		.content {
			float:left;
			width:65%;
			font-size:14px;
		}
	}
}

body.post-type-archive-multimedia, body.single-multimedia {
	.home-articles {
		h2 {
			a {
				color:$blue-color;
				&:hover {
					color:$link-color;
				}
			}
		}
	}
}
body.search-results {
	.home-articles {
		.top {
			.filters {
				select {
					margin-left: 20px;
				}
			}
		}
	}
}
body.single {
	.breadcrumbs {
		position:relative;
		#crumbs {
			padding-left: 100px;
		}
	}
	.post-thumbnail {
		margin-left: -100px;
		padding-right: 50px;
		position:relative;
		img {
			max-width:100%;
			min-width:100%;
			margin:20px 0 60px 0;
		}
		.picture-social {
			position:absolute;
			width:25px;
			bottom:0;
			right:0;
			a {
				transition: all 0.3s;
				opacity:1;
				margin-top: 15px;
				&:hover {
					opacity:0.7;
				}
				span {
					display:none !important;
				}
			}
			a.addthis_button_facebook {
				width:25px;
				height:25px;
				background:url(../images/facebook4.svg) no-repeat center center;
				background-size: contain;
			}
			a.addthis_button_linkedin {
				width:25px;
				height:25px;
				background:url(../images/linkedin4.svg) no-repeat center center;
				background-size: contain;
			}
			a.addthis_button_twitter {
				width:25px;
				height:25px;
				background:url(../images/twitter4.svg) no-repeat center center;
				background-size: contain;
			}
		}
		.youtube-single, .podcast-single {
			height:0;
			padding-top: 56.25%;
			position:relative;
			margin:20px 0 60px 0;
			iframe {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				width:100%;
				height:100%;
			}
		}
	}
}
body.single-post {
	.breadcrumbs {
		margin-bottom: 0;
	}
}
body.author-overview {
	.breadcrumbs {
		position:relative;
		color:#fff;
		a {
			color:#fff;
		}
		&:after {
			content:"";
			display:block;
			position:absolute;
			top:100%;
			left:0;
			right:0;
			height:400px;
			background:$blue-color;
		}
	}
}
body.author {
	.breadcrumbs {
		&:after {
			display:none !important;
		}
	}
	.editors-pick .slide .inside .nr {
		display:none !important;
	}
}

.single-detail {
	position:relative;
	z-index:2;
	padding:0 0 10rem 0;
	.content, h1 {
		padding-left:100px;
		max-width:960px;
	}
	h1 {
		color:$blue-color;
		font-weight:400;
		font-size:3.6rem;
		margin-bottom: 0;
	}
	.article-details {
		margin-bottom: 40px;
		border-bottom:1px solid #ccc;
		padding:5px 0;
		> div, span, a {
			display:inline-block;
			margin-right: 15px;
			font-size:14px;
			div {
				display:inline;
			}
		}
		a {
			color:$link-color;
		}
		ul {
			margin:0;
			padding:0;
			list-style:none;
			li {
				display:inline-block;
				margin-right: 10px;
				font-weight:700;
				color:#7C7C7C;
				background-color: transparent;
			    background-repeat: no-repeat;
			    background-position: left center;
				padding-left: 20px;
				position:relative;
				font-size:13px;
				a:hover {
					text-decoration: underline;
				}
				&.categories {
					background-image: url(../images/post_category.png);
					a {
						margin-right: 3px;
					}
					a:after {
						content:",";
						color: #7C7C7C;
					}
					a:last-child:after {
						display:none;
					}
				}
				&.date {
					background-image: url(../images/post_date.png);
				}
				&.author {
					background-image: url(../images/post_author.png);
				}
				&.views {
					background-image: url(../images/post_views.png);
					div {
						display:inline;
					}
				}
			}
		}
		a:hover {
			color:$link-color;
		}
	}
	.content {
		img {
			margin:60px 20px 60px -100px;
			max-width:960px;
		}
		p {
			font-size:15px;
		}
		blockquote {
			border-left:8px solid $blue-color;
			font-size:32px;
			margin:50px 0;
			padding-left: 50px;
			font-style:italic;
		}
	}
}

body.has-featured-image {
	.breadcrumbs:after {
		content: "";
	    display: block;
	    position: absolute;
	    top: 100%;
	    left: 0;
	    right: 0;
	    height: 400px;
	    background: #e6e6e6;
	}
}
body.no-featured-image {
	.grayBg {
		background: #e6e6e6;
		padding:3rem 0;
	}
}

body.single {
	.grayBg {
		padding-top: 3rem;
	}
}

@media only screen and (max-width:1400px) {
	.countries-dropdown {
		.pageAlign {
			max-width:1000px;
		}
	}
	.editors-pick {
		.container {
			width:1000px;
			margin-left:auto;
			margin-right: auto;
		}
		.slide {
			width:250px;
			&.big-slide {
				width:500px;
			}
		}
	}
}

@media only screen and (max-width:1100px) {
	header {
		box-shadow: 0 0 25px 0 rgba(0,0,0,.1);
		&.small-header {
			.main-header {
				transform:none;
				padding:10px 0;
				a.logo {
					margin-top: 0px;
				}
			}
		}
		.top-bar {
			display:none;
		}
		.main-header {
			padding:10px 0;
			transform:none;
			nav {
				position:fixed;
				top:70px;
				left:0;
				right:0;
				bottom:0;
				background:#fff;
				margin:0 !important;
				opacity:0;
				visibility: hidden;
				overflow-y: scroll;
				&.visible {
					opacity:1;
					visibility: visible;
				}
				ul {
					li {
						display:block;
						border-top:1px solid #f3f3f3;
						&.menu-item {
							display:block;
						}
						span.arrow {
							right:0 !important;
							cursor:pointer;
							background-color:#ececec !important;
							top:7px !important;
						}
						a {
							text-align:center;
						}
						ul {
							position:static;
							display:block;
							opacity:1;
							visibility: visible;
							transform:none;
							width:auto;
							padding:0;
							display:none;
							&.open {
								display:block;
							}
							&.countries-dropdown {
								position:static;
								transform:none !important;
								@include clearfix;
								width:auto;
							}
							li {
								a {
									display:block;
									text-align: center;
								}
							}
						}
					}
				}
			}
			a.logo {
				margin-top: 0;
				width: 112px !important;
				height: 48px !important;
			}
			.search-trigger {
				margin: 19px 0 0 30px;
			}
			.language {
				margin-top: 4px;
			}
			.responsive-nav {
				display:block;
				margin-top: 10px;
			}
		}
	}
	.trending {
		.main {
			.inside {
				height:auto;
				padding:10rem 0 0 0;
				.content {
					padding:20px;
					position:relative;
					@include clearfix;
					.left {
						width:100%;
						h2 {
							font-size:24px;
						}
						p {
							margin-bottom: 0;
						}
					}
					.right {
						width:100%;
					}
				}
			}
		}
		.article {
			width:50%;
		}
	}
	.editors-pick {
		.container {
			width:auto;
		}
		.slide {
			.inside {
				h2 {
					font-size:18px;
				}
			}
			&.big-slide {
				.inside {
					.nr {
						font-size:5rem;
					}
					h2 {
						line-height:24px;
						a {
							font-size:18px;
						}
					}
				}
			}
		}
	}
	footer {
		.col30 {
			ul {
				width:100%;
				padding-right: 0;
			}
		}
	}
	.subpage-banner {
		.box {
			display:block;
			flex-wrap:nowrap;
		}
		.image, .youtube-container {
			width:100%;
			height:0;
			padding-bottom: 56.25%;
		}
		.right {
			width:100%;
			padding:40px;
		}
	}
	.authors {
		.author {
			width:50%;
			.content {
				a {
					font-size:20px;
				}
			}
		}
	}
	.single-detail {
		.content {
			img {
				max-width:100%;
			}
		}
	}
}

@media only screen and (max-width:900px) {
	header {
		.main-header {
			form {
				left:0;
			}
		}
	}
	.featured {
		.article {
			width:50%;
			&:first-child {
				width:100%;
			}
		}
	}
	.home-articles {
		.article {
			width:50%;
			&:nth-child(3n+1) {
				clear:none;
			}
			&:nth-child(2n+1) {
				clear:left;
			}
		}
	}
	.trending {
		.main {
			.inside {
				height:auto;
				padding-bottom:0;
				.content {
					position:relative;
					.left {
						float:none;
					}
					.right {
						display:none;
					}
				}
			}
		}
	}
	.multimedia {
		.article {
			width:50%;
			&:first-child {
				width:100%;
			}
		}
	}
	footer {
		.col30 {
			width:50%;
			&:first-child {
				width:100%;
				padding-right: 0;
			}
		}
	}
	.column-image {
		.left {
			padding-right: 0;
			width:100%;
		}
		.right {
			width:100%;
		}
	}
	.author-information {
		.left {
			width:100%;
		}
		.right {
			width:100%;
			padding-left: 0;
			margin-top: 40px;
		}
	}
	.contact-section {
		.container {
			.left {
				width:100%;
				padding-right: 0;
				border-right:none;
			}
			.right {
				width:100%;
				padding-left: 0;
			}
		}
	}
}

@media only screen and (max-width:700px) {
	header {
		.top-bar {
			nav {
				ul {
					li {
						a {
							padding:10px;
						}
					}
				}
			}
		}
	}
	body.author-overview {
		.breadcrumbs {
			&:after {
				display:none;
			}
		}
	}
	.authors {
		//padding-top: 5rem;
		.top {
			h2 {
				color:$blue-color;
			}
		}
		.author {
			margin-bottom: 20px;
		}
	}
	.simple-content {
		img.alignleft {
			float:none;
			margin:10px 0;
		}
	}
}

@media only screen and (max-width:600px) {
	html {
		font-size:35%;
	}
	.pageAlign, .pageAlign2 {
		padding:0 10px;
	}
	header {
		.main-header {
			.language {
				margin-top: 9px !important;
				.selected {
					font-size:12px;
				}
				.wpml-ls ul{
					top:37px;
				}
			}
			nav {
				> ul {
					> li {
						ul.countries-dropdown {
							li {
								width:50%;
							}
						}
					}
				}
			}
			.search-trigger {
				margin-left: 10px !important;
			}
		}
	}
	.featured {
		.container {
			margin:0;
		}
		.article {
			padding:20px 0;
			width:100%;
			.article-link {
				left:0;
				right:0;
			}
			&:first-child {
				.inside {
					.content {
						padding-left: 20px;
						a.category {
							font-size:12px;
						}
						h2 {
							font-size:300px;
							font-size:15px;
						}
						p {
							display:none;
						}
					}
				}
			}
		}
	}
	.multimedia {
		.container {
			margin:0;
		}
		.article {
			width:100%;
			padding:20px 0;
		}
	}
	.editors-pick {
		.slick-next, .slick-prev {
			display:none !important;
		}
	}
	footer {
		.col30 {
			width:100%;
			padding-right: 0;
		}
		.bottom {
			.right {
				float:left;
				margin-top: 15px;
			}
		}
	}
	section {
		.top {
			h2 {
				display:block;
				width:100%;
			}
			a {
				//float:left !important;
				margin-left: 0 !important;
			}
			.filters {
				float:left !important;
			}
		}
	}
	.home-articles {
		padding-top: 20px;
		.container {
			margin:0;
			padding-top: 0;
		}
		.article {
			width:100%;
			padding:13px 0;
		}
	}
	.trending {
		.main {
			padding:5px;
			.inside {
				.content {
					p {
						font-size:12px;
						line-height:normal;
					}
				}
			}
		}
		.container {
			margin:0 -5px;
		}
		.article {
			padding:5px;
			.article-link {
				top:5px;
				left:5px;
				right:5px;
				bottom:5px;
			}
			.inside {
				padding-bottom: 80%;
				h3 {
					padding:5px;
					font-size:12px;
				}
			}
		}
	}
	.subscribe {
		form {
			input {
				width:100%;
			}
			button {
				width:100%;
				margin-top:10px;
			}
		}
	}
	.two-columns {
		.column {
			width:100%;
		}
	}
	.author-information {
		.socials {
			float:left;
			clear:left;
		}
	}
	.authors {
		.container {
			display:flex;
			flex-wrap:wrap;
		}
		.author {
			.content {
				left:15px;
				right:15px;
				padding:5px;
				position:static;
				span {
					font-size:12px;
				}
				a {
					font-size:13px;
				}
			}
		}
	}
	.contact-section {
		.map {
			margin-top: 30px;
			padding-bottom: 60%;
		}
		.col50 {
			width:100%;
		}
		.row {
			.icn {
				float:none;
			}
		}
	}
	.single-detail {
		.article-details, .content, h1 {
			padding-left: 0;
			max-width:100%;
		}

		.content {
			blockquote {
				font-size:18px;
				padding-left: 20px;
			}
			img {
				margin:30px 0;
				float:none;
			}
			.post-content {
				p:nth-child(2) {
					font-size:16px;
					line-height:28px;
				}
			}
		}
	}
	body.single .breadcrumbs #crumbs {
		padding-left: 0;
	}
	body.single .podcast-single, body.single .youtube-single {
		margin-left: 0;
	}
	body.single .post-thumbnail {
		margin:0;
		padding-right:0;
		.picture-social {
			display:none;
		}
	}
	body {
		.filters {
			margin-left: 0 !important;
			.filter {
				.nice-select {
					width:100%;
					margin:3px 0;
				}
			}
		}
	}
	.author-information {
		.author-image {
			img {
				max-height:150px;
				min-width:0;
			}
		}
	}
}
